import { CreditPlanUpgradeProps, CreditPlanUpgradeStateProps } from '../../types/components/credits/CreditPlanUpgrade';
import { PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';

import PlanPriceTag from '../shared/PlanPriceTag';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import getPlanVariants from '../../hooks/getPlanVariants';

import creditsHelpers from '../../helpers/creditsHelpers';
import subscriptionPlanHelpers from '../../helpers/subscriptionPlanHelpers';

export const inactiveCreditPlanButtonClasses = 'border-gray-600 text-gray-600';
export const activeCreditPlanButtonClasses = 'text-white bg-a-blue'

const CreditPlanUpgrade: React.FC<CreditPlanUpgradeProps> = (props: CreditPlanUpgradeProps) => {
  const { currentPlan, creditsMissing, onUpgradePlanSelected, licenseId, licenseTypes, onPlanVariantsFiltered } = props;
  const { getFilteredCreditPlans } = creditsHelpers;
  const { getVerboseInterval } = subscriptionPlanHelpers;
  const { data: planVariants } = getPlanVariants(licenseId);

  const [selectedPlan, setSelectedPlan] = useState<PlanVariantResponse>();
  const [filteredPlans, setFilteredPlans] = useState<Array<PlanVariantResponse>>();

  useEffect(() => {
    if (!planVariants?.length) return;
    const filteredPlans = getFilteredCreditPlans(creditsMissing, currentPlan, planVariants);
    setFilteredPlans(filteredPlans);
    onPlanVariantsFiltered(filteredPlans);
  }, [planVariants]);

  useEffect(() => {
    if (!filteredPlans) return;
    if (!selectedPlan) selectPlan(filteredPlans[0]);
  }, [filteredPlans]);

  const getIncreasePlanSubtitle = (): string => {
    if (!filteredPlans?.length && !selectedPlan) return '';
    const plan = selectedPlan || filteredPlans[0];
    return `Increase your ${getVerboseInterval(currentPlan.plan_variant)} credits from ${currentPlan.plan_variant.recurring_credits} to ${plan.recurring_credits} credits.`;
  }

  const getCreditsPerIntervalSubtitle = (): string => {
    return `Credits per ${currentPlan.plan_variant.interval}`;
  }

  const selectPlan = (plan: PlanVariantResponse) => {
    onUpgradePlanSelected(plan);
    setSelectedPlan(plan);
  }

  const renderCreditPlanButton = (config: PlanVariantResponse): React.ReactElement => {
    return (
      <button
        className={`
          py-[6px] px-5 justify-center items-center gap-2.5 self-stretch rounded-[44px] border-[1px] inline-block mr-2
          ${selectedPlan?.id === config.id ? activeCreditPlanButtonClasses : inactiveCreditPlanButtonClasses}`
        }
        onClick={() => selectPlan(config)}
        cy-test-id={`credit-plan-button-${config.id}`}
        key={`credit-pan-button-${config.id}`}
      >
        {config.recurring_credits}
      </button>
    );
  };

  const renderPrice = (): React.ReactElement => {
    if (
      !licenseTypes ||
      !selectedPlan
    ) return <></>;
    return (
      <div cy-test-id="price-block">
        <PlanPriceTag plan={selectedPlan} alignment="vertical"/>
        <span className="block text-a-lighter-gray leading-22 text-[14px] font-normal" cy-test-id="user-facing-cost">
          Equals to ${selectedPlan.user_facing_cost_per_credit}/credit
        </span>
      </div>
    )
  };

  return (
    <div className="w-full" cy-test-id="credit-plan-selection">
      <h1 className="block text-white eurostile uppercase text-24 font-bold leading-[36px] tracking-[0.48px]" cy-test-id="title">
        Upgrade your plan
      </h1>
      <h3 className="block mt-1 mb-6 text-a-lighter-gray inter text-[14px] font-normal leading-22" cy-test-id="subtitle">
        {getIncreasePlanSubtitle()}
      </h3>
      <div className="block">
        <span className="block text-white inter text-16 leading-24 mb-3" cy-test-id="credits-per-interval-subtitle">
          {getCreditsPerIntervalSubtitle()}
        </span>
        <div className="block w-full min-h-[41px]">
          {filteredPlans?.map(config => renderCreditPlanButton(config))}
        </div>
      </div>
      <div className="block mt-6">
        {renderPrice()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): CreditPlanUpgradeStateProps => ({
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(CreditPlanUpgrade);
