import { CreditPackPriceTagProps } from '../../types/components/shared/CreditPackPriceTag';

import creditsHelpers from '../../helpers/creditsHelpers';

const CreditPackPriceTag: React.FC<CreditPackPriceTagProps> = ({
  creditPack,
  isCustom,
  customAmount,
  // TODO test
  priceRanges,
}) => {
  const { calculateCustomAmountPrice, getCustomAmountPriceRangeFor } = creditsHelpers;

  const getCostPerCredit = (): string => {
    if (!isCustom)
      return parseFloat(creditPack.adjusted_cost_per_credit || creditPack.cost_per_credit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const priceRange = getCustomAmountPriceRangeFor(customAmount, priceRanges);
    return (priceRange?.price || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  const getCreditPackPrice = (): string =>
    isCustom ?
      calculateCustomAmountPrice(customAmount, priceRanges).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
      parseFloat(creditPack.adjusted_price || creditPack.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const getTotalPrice = (): string => {
    const price = isCustom ?
      calculateCustomAmountPrice(customAmount, priceRanges).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
      getCreditPackPrice();
    return price;
  }

  const getOldPrice = (): string => {
    const price = isCustom ?
      calculateCustomAmountPrice(customAmount, priceRanges).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
      parseFloat(creditPack.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return price;
  }

  return (
    <div cy-test-id="credit-pack-price-tag" className="inline-block mr-3">
      {
        creditPack &&
        <>
          <span className="flex flex-row items-center">
            {
              creditPack.adjusted_cost_per_credit &&
              <span cy-test-id="old-price-block" className="text-[18px] text-red-800 line-through mr-2">{`$${getOldPrice()}`}</span>
            }
            <span className="text-white text-[20px] leading-44 font-bold inter">$</span>
            <span className="text-white text-[32px] leading-44 font-bold inter" cy-test-id="total-cost">
              {getTotalPrice()}
            </span>
          </span>
          <span className="block text-a-lighter-gray leading-22 text-[14px] font-normal" cy-test-id="cost-per-credit">
            Equals to ${getCostPerCredit()}/credit
          </span>
        </>
      }
    </div>
  )
};

export default CreditPackPriceTag;
