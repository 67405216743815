import { SelectedPlanVariantDetailsProps } from '../../types/components/checkout/SelectedPlanVariantDetails';

import { ClockIcon } from '../../modules/Icons';

import creditsHelpers from '../../helpers/creditsHelpers';
import moment from 'moment';

const SelectedPlanVariantDetails: React.FC<SelectedPlanVariantDetailsProps> = ({
  selectedPlanVariant,
  customRenewalTooltip,
  renewalPrice
}) => {
  const { getCreditsPerIntervalTextFor } = creditsHelpers;

  const getRenewalTooltipText = (): string => {
    if (customRenewalTooltip) return customRenewalTooltip;
    const { interval } = selectedPlanVariant;
    let renewalDate: any;

    // This is fixing an alarming bug - seems the renewal price isn't properly applied somewhere.
    let nextRenewalPrice = null;

    switch (interval) {
      case 'month':
        renewalDate = moment().add(1, 'M');
        nextRenewalPrice = parseFloat(selectedPlanVariant.adjusted_price);
        break;
      case 'year':
        renewalDate = moment().add(1, 'y');
        nextRenewalPrice = parseFloat(selectedPlanVariant.price);
        break;
      default: break;
    };
    const renewPrice = renewalPrice || nextRenewalPrice || parseFloat(selectedPlanVariant.adjusted_price || selectedPlanVariant.price);
    const dateString = renewalDate.format('MMMM Do YYYY');
    return `Renews at $${renewPrice.toFixed(2)} starting ${dateString}`;
  }

  const getPlanPrice = (): number => {
    return parseFloat(selectedPlanVariant?.adjusted_price || selectedPlanVariant?.price || '0');
  }

  const title = `${selectedPlanVariant.plan.name} Plan`;
  const billingTooltip = `Billed ${selectedPlanVariant.interval === 'month' ? 'monthly' : 'annually'}`;

  return (
    <div className="w-full" cy-test-id="selected-plan-variant-details">
     <div className="w-full flex text-white">
       <span className="inter text-18 leading-28 font-semibold" cy-test-id="plan-title">
         {title}
       </span>
       <span className="inter ml-auto text-18 leading-28 font-semibold" cy-test-id="plan-price">
         ${getPlanPrice().toLocaleString('en-US', {
             minimumFractionDigits: 2,
             maximumFractionDigits: 2
           })}
       </span>
     </div>
     <div className="w-full flex text-a-gray">
       <span className="inter text-14 leading-22 font-normal" cy-test-id="credits-per-interval">
         {getCreditsPerIntervalTextFor(selectedPlanVariant)}
       </span>
       <span className="inter ml-auto text-14 leading-22 font-normal" cy-test-id="billing-tooltip">
         {billingTooltip}
       </span>
     </div>
     <hr className="w-full my-3 border-t-[1px] border-[#4d4d4d] border-solid"/>
     <div className="w-full flex items-center align-center justify-center gap-2 text-a-gray" cy-test-id="renewal-tooltip">
       <ClockIcon />
       <span className="text-14 leading-22 inter">{getRenewalTooltipText()}</span>
     </div>
    </div>
  );
}

export default SelectedPlanVariantDetails;
