import { CreditPackSelectionProps } from '../../types/components/credits/CreditPackSelection';
import { CreditPackResponse, CustomCreditPackPriceConfig } from '../../types/api/CreditsTypes';

import {
  PlusIcon,
  MinusIcon,
} from '../../modules/Icons';

import CreditPackPriceTag from '../shared/CreditPackPriceTag';

import { useState, useEffect, useRef } from 'react';
import getCreditPacks from '../../hooks/getCreditPacks';

import creditsHelpers from '../../helpers/creditsHelpers';

export const inactiveCreditPackButtonClasses = 'border-gray-600 text-gray-600';
export const activeCreditPackButtonClasses = 'text-white bg-a-blue'
export const validCustomAmountInputClasses = 'border-[#4D5457] focus:border-[#00A1E0]';
export const invalidCustomAmountInputClasses = 'border-red-800';

const creditPackButtonClasses = 'py-[6px] px-5 justify-center items-center gap-2.5 self-stretch rounded-[44px] border-[1px] inline-block';

// TODO test dynamic price ranges
const CreditPackSelection: React.FC<CreditPackSelectionProps> = (props: CreditPackSelectionProps) => {
  const {
    creditsMissing = 0,
    onCreditAmountSelected,
    onCreditPackSelected,
    onValidationStatusChanged,
    title = 'Buy Credit Pack',
    autoSelectFirstOption,
    licenseId,
  } = props;

  const { data: creditPacks } = getCreditPacks(licenseId);
  const { getFilteredCreditPacks } = creditsHelpers;

  const customAmountInputRef = useRef(null);

  const [selectedCreditPack, setSelectedCreditPack] = useState<CreditPackResponse>();
  // TEMP solution, change and refactor
  const customCreditPackConfig: CreditPackResponse = {
    cost_per_credit: '',
    id: 0,
    price: '0',
    recurring_credits: 0,
  };
  const [minimalCustomAmount, setMinimalCustomAmount] = useState<number>(0);
  const [customAmount, setCustomAmount] = useState<number | undefined>();
  const [customAmountSelected, setCustomAmountSelected] = useState(false);
  const [invalidCustomAmount, setInvalidCustomAmount] = useState<boolean>(false);
  const [filteredCreditPacks, setFilteredCreditPacks] = useState<Array<CreditPackResponse>>();
  const [customCreditPackPriceRange, setCustomCreditPackPriceRange] = useState<Array<CustomCreditPackPriceConfig>>();

  useEffect(() => {
    if (!creditPacks?.data?.length) return;
    setCustomCreditPackPriceRange(creditPacks.custom_credit_packs_prices);
    const filteredCreditPacks = getFilteredCreditPacks(creditsMissing, creditPacks.data);
    setFilteredCreditPacks(filteredCreditPacks);
    const minimalCustomAmount = Math.max(1, creditsMissing);
    setMinimalCustomAmount(minimalCustomAmount);
  }, [creditPacks]);

  useEffect(() => {
    const customAmountInvalid = customAmountIsInvalid();
    setInvalidCustomAmount(customAmountInvalid);
    onValidationStatusChanged((!!selectedCreditPack || !!customAmountSelected) && !customAmountInvalid);
  }, [customAmount, selectedCreditPack, customAmountSelected]);

  useEffect(() => {
    if (!filteredCreditPacks) return;
    if (autoSelectFirstOption) {
      filteredCreditPacks.length ?
        selectCreditPack(filteredCreditPacks[0]) :
        selectCustomAmount();
      return;
    }
    if (!selectedCreditPack && !customAmountSelected) {
      filteredCreditPacks.length ?
        selectCreditPack(filteredCreditPacks[0]) :
        selectCustomAmount();
    }
  }, [filteredCreditPacks])

  const customAmountIsInvalid = (): boolean => {
    if (!customAmountSelected) return false;
    return !customAmount || customAmount < minimalCustomAmount;
  }

  const selectCreditPack = (config: CreditPackResponse) => {
    setSelectedCreditPack(config);
    onCreditPackSelected(config, false, undefined, customCreditPackPriceRange);
    setCustomAmountSelected(false);
    setCustomAmount(minimalCustomAmount);
  }

  const selectCustomAmount = () => {
    setSelectedCreditPack(undefined);
    setCustomAmount(minimalCustomAmount);
    onCreditPackSelected(customCreditPackConfig, true, minimalCustomAmount, customCreditPackPriceRange);
    setInvalidCustomAmount(customAmountIsInvalid());
    setCustomAmountSelected(true);
  }

  const renderCreditPackButton = (config: CreditPackResponse): React.ReactElement => {
    return (
      <button
        className={`
          ${creditPackButtonClasses}
          ${selectedCreditPack?.id === config.id && !customAmountSelected ? activeCreditPackButtonClasses : inactiveCreditPackButtonClasses}`
        }
        onClick={() => selectCreditPack(config)}
        cy-test-id={`credit-pack-button-${config.id}`}
        key={`credit-pack-button-${config.id}`}
      >
        {config.recurring_credits}
      </button>
    );
  };

  const renderCustomAmountButton = (): React.ReactElement => {
    return (
      <button
        className={`
          ${creditPackButtonClasses}
          ${customAmountSelected ? activeCreditPackButtonClasses : inactiveCreditPackButtonClasses}`
        }
        onClick={selectCustomAmount}
        cy-test-id={`credit-pack-button-custom`}
      >
        Custom
      </button>
    );
  }

  const amountChangeHandler = (newAmount: number) => {
    setCustomAmount(newAmount);
    onCreditAmountSelected(newAmount);
  }

  const amountChangeButtonHandler = (increase: boolean) => {
    if (!increase && customAmount - 1 < minimalCustomAmount) return;
    const newAmount = increase ? customAmount + 1 : customAmount - 1;
    customAmountInputRef.current.value = newAmount;
    amountChangeHandler(newAmount);
  }

  const renderAmountChangeButton = (increase: boolean): React.ReactElement => {
    return (
      <button
        className="w-[40px] h-[40px] p-[10px] justify-center items-center rounded-[50%] bg-gray-800 inline-block align-middle"
        onClick={() => amountChangeButtonHandler(increase)}
        cy-test-id={`amount-change-button-${increase ? 'increase' : 'decrease'}`}
      >
        {increase ? <PlusIcon className="stroke-white"/> : <MinusIcon className="stroke-white"/>}
      </button>
    )
  }

  const renderCustomAmountInput = (): React.ReactElement => {
    if (!customAmountSelected) return <></>;
    return (
      <div
        className="inline-block align-top ml-auto mt-1"
        cy-test-id="custom-amount-controls"
      >
        {renderAmountChangeButton(false)}
        <input
          type="number"
          min={minimalCustomAmount}
          className={
            `no-arrows-number-input w-[70px] mx-2 text-center py-1 px-4 border bg-[#252B2D] rounded-[5px] text-white text-[16px] focus:outline-0 focus:border-1
             ${invalidCustomAmount ? invalidCustomAmountInputClasses : validCustomAmountInputClasses}
            `
          }
          onChange={(e) => {
            const value = parseInt(e.target.value);
            amountChangeHandler(value);
          }}
          defaultValue={minimalCustomAmount}
          ref={customAmountInputRef}
          cy-test-id="custom-amount-input"
        />
        {renderAmountChangeButton(true)}
      </div>
    )
  }

  const renderPrice = (): React.ReactElement => {
    if (
      !selectedCreditPack &&
      !customAmountSelected ||
      !customAmount &&
      customAmount !== 0
    ) return <></>;
    return <CreditPackPriceTag
      creditPack={customAmountSelected ? customCreditPackConfig : selectedCreditPack}
      customAmount={customAmount}
      isCustom={customAmountSelected}
      priceRanges={customCreditPackPriceRange}
    />
  };

  return (
    <div className="w-full" cy-test-id="credit-pack-selection">
      <h1 className="block text-white eurostile uppercase text-24 font-bold leading-[36px] tracking-[0.48px]" cy-test-id="title">
        {title}
      </h1>
      <h3 className="block mt-1 mb-6 text-a-lighter-gray inter text-[14px] font-normal leading-22" cy-test-id="subtitle">
        This is a one-time purchase.
      </h3>
      <div className="block">
        <span className="block text-white inter text-16 leading-24 mb-3" cy-test-id="number-of-credits-section-title">
          Number of credits
        </span>
        <div className="flex flex-row flex-wrap gap-2 w-full min-h-[41px]">
          {filteredCreditPacks?.map(config => renderCreditPackButton(config))}
          {renderCustomAmountButton()}
        </div>
      </div>
      <div className="flex mt-6">
        {renderPrice()}
        {renderCustomAmountInput()}
      </div>
    </div>
  );
};

export default CreditPackSelection;
