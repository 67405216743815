import { FetchHookResponse } from '../types/hooks/hooksTypes';
import { CreditPackVariantsResponse } from '../types/api/CreditsTypes';
import { ApplicationState } from '../types/state/storeTypes';
import { LicenseId } from '../types/api/LicenseTypesTypes';

import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCreditPacks } from '../state/actions/creditPacksCacheActions';

import { ENV } from '../constants/environments';

export default function getCreditPacks(license_id: LicenseId): FetchHookResponse<CreditPackVariantsResponse> {
  const creditPacksUrl = `/credit_pack_variants?license_id=${license_id}`;
  const baseUrl = ENV.api.baseURL;

  const dispatch = useDispatch();
  const state: ApplicationState = useSelector((state) => state) as ApplicationState;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const currentState = state.creditPacksCache.creditPacks[license_id];
    if (!!currentState && !!Object.keys(currentState)?.length) return;

    const getCreditPacks = async () => {
      setLoading(true);
      try {
        // TODO - this needs to pass in a license_id param to the back-end to get correct prices for users without a paid subscription
        const creditPacksResult: AxiosResponse<CreditPackVariantsResponse> = await axios.get(`${baseUrl}${creditPacksUrl}`, {
          headers: { authorization: state?.auth?.user?.token }
        });
        dispatch(setCreditPacks(creditPacksResult.data, license_id));
        setLoading(false);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    }

    getCreditPacks();
  }, []);

  return { loading, error, data: state.creditPacksCache.creditPacks[license_id] };
}
