import {
  UserMenuStateProps,
  UserMenuProps,
  UserMenuItemConfig
} from '../../types/components/shared/UserMenu';
import { ApplicationState } from '../../types/state/storeTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { User } from '../../types/api/UsersTypes';

import Link from 'next/link';

import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { signOut } from 'next-auth/react';
import isMobile from '../../hooks/isMobile';

import { AccountNavigation, AccountSupportNavigation } from '../../staticData/Navigation';

// exporting for test purposes, all wraper style changes should be made here
export const mobileWrapperClasses = 'flex flex-col gap-3 rounded-[5px]';
export const desktopWrapperClasses =
  'bg-a-dark-gray p-6 flex flex-col rounded-[5px] drop-shadow-md mt-[15px] w-full';

const UserMenu: React.FC<any> = (props: UserMenuProps) => {
  const { subscription, user, linkCallback } = props;
  const [menuOptions, setMenuOptions] = useState<Array<UserMenuItemConfig>>([]);
  const [userFirstName, setUserFirstName] = useState<string>(user?.first_name || '');
  const [userLastName, setUserLastName] = useState<string>(user?.last_name || '');

  const mobile = isMobile();

  useEffect(() => {
    let options: Array<UserMenuItemConfig> = AccountNavigation;
    if (!subscription || !user?.should_see_manage_subscription)
      options = options.filter(option => option.text !== 'Manage Subscription');
    if (!subscription)
      options = options.filter((option) => option.text !== 'Manage Team');
    if (subscription && subscription.license.id < 2)
      options = options.filter((option) => option.text !== 'Manage Team');
    setMenuOptions(options);
  }, [subscription]);

  useEffect(() => {
    if (!user) return;
    setUserFirstName(user.first_name);
    setUserLastName(user.last_name);
  }, [user]);

  const sharedLinkClasses =
    'flex items-center text-a-light-gray rounded-[3px] px-2 py-1 hover:text-white hover:bg-[#252B2D] text-[14px] leading-[32px]';

  const handleLinkClick = () => {
    if (!linkCallback) return;
    linkCallback();
  };

  return (
    <ul className={mobile ? mobileWrapperClasses : desktopWrapperClasses} cy-test-id="menu-wrapper">
      <li className="flex items-center mb-3" cy-test-id="user-detail-section">
        <span className="rounded-full w-[40px] h-[40px] bg-white text-a-dark-gray text-[16px] font-medium flex justify-center items-center tracking-tight">
          {userFirstName.length ? userFirstName[0] : ''}
          {userLastName.length ? userLastName[0] : ''}
        </span>
        <Link className="text-a-blue text-[14px] pl-2" href="/accounts/profile">
          {userFirstName} {userLastName}
        </Link>
      </li>
      {menuOptions.map((item, k) => (
        <li key={k}>
          <Link className={sharedLinkClasses} href={item.href} onClick={handleLinkClick}>
            {item.text}
          </Link>
        </li>
      ))}
      <hr className="h-px mt-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <section className='py-2'>
        {AccountSupportNavigation.map((item, k) => (
          <li key={k}>
            <Link
              className={sharedLinkClasses + ' flex items-center gap-2'}
              href={item.href}
              onClick={handleLinkClick}>
              {item.icon && item.icon}
              {item.text}
            </Link>
          </li>
        ))}
      </section>
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <li cy-test-id="sign-out-link">
        <a onClick={() => signOut()} className={`${sharedLinkClasses} cursor-pointer`}>
          Logout
        </a>
      </li>
    </ul>
  );
};

const mapStateToProps = (state: ApplicationState): UserMenuStateProps => ({
  subscription: state.auth.subscription as SubscriptionDetail,
  user: state.auth.user as User
});

export default connect(mapStateToProps)(UserMenu);
