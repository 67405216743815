import {
  DownloadLicenseCertificateButtonProps,
  DownloadLicenseCertificateButtonStateProps
} from '../../types/components/license-certificate/DownloadLicenseCertificateButton';
import { User } from '../../types/api/UsersTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import Image from 'next/image';

import { addMessage } from '../../state/actions/messagesActions';
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import licenseCertificateHelpers from '../../helpers/licenseCertificateClientSide';

export const defaultButtonText = 'Download License Certificate (PDF)';
export const disabledButtonText = 'Preparing your PDF...';

const DownloadLicenseCertificateButton: React.FC<DownloadLicenseCertificateButtonProps> = ({
  user,
}) => {
  const { downloadLicenseCertificatePdf } = licenseCertificateHelpers;
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  if (!user?.id) return <></>;

  const clickHandler = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await downloadLicenseCertificatePdf(user, () => setLoading(false));
    } catch (e) {
      dispatch(addMessage({
        type: 'error',
        body: 'Something went wrong downloading your License Certificate. Please try again later or contact support.',
      }));
      setLoading(false);
    }
  }

  return (
    <button
      onClick={clickHandler}
      className="flex flex-row gap-1 disabled:opacity-50"
      type="button"
      cy-test-id="download-license-certificate-button"
      disabled={loading}
    >
      <Image
        src="/img/license-certificate/icon.png"
        width={24}
        height={24}
        alt=""
      />
      <span className="text-a-blue inter text-14 md:text-16">
        {loading ? disabledButtonText : defaultButtonText}
      </span>
    </button>
  )
};

const mapStateToProps = (state: ApplicationState): DownloadLicenseCertificateButtonStateProps => ({
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(DownloadLicenseCertificateButton);
