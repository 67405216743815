import { SuccessfulPurchaseNotificationProps } from '../../types/components/shared/SuccessfulPurchaseNotification';

import ColoredSidebarNotification from './ColoredSidebarNotification';

export const creditPackMessage = 'Credit purchase successful!';
export const creditPackMessageWithAmount = 'new credits purchased successfully!';
export const creditPlanMessage = 'Subscription upgrade successful!';
export const additionalMessage = 'You can now access your new credits.';

const SuccessfulPurchaseNotification: React.FC<SuccessfulPurchaseNotificationProps> = ({
  purchaseType,
  creditsAmount,
}) => {
  const getCreditPackPurchaseText = (): string =>
    creditsAmount ? `${creditsAmount} ${creditPackMessageWithAmount}` : creditPackMessage;

  const getSubscriptionUpgradeText = (): string => creditPlanMessage;

  const getNotificationText = (): string =>
    purchaseType === 'creditPack' ? getCreditPackPurchaseText() : getSubscriptionUpgradeText();

  const renderNotificationBody = (): React.ReactElement => {
    return (
      <>
        <span className="block font-normal text-white inter text-14 md:text-16 leading-24" cy-test-id="main-message">
          {getNotificationText()}
        </span>
        <span className="block mt-1 font-normal inter text-12 md:text-14 text-a-light-gray leading-22" cy-test-id="additional-message">
          {additionalMessage}
        </span>
      </>
    );
  };

  return <ColoredSidebarNotification color="green" body={renderNotificationBody()} />;
}

export default SuccessfulPurchaseNotification;
