export default function errorRedirectHandler(
  error: any,
  redirectRoute?: string,
  errorCode?: number,
): { redirect: { destination: string, permanent: boolean }} {
  const status = error?.response?.status || errorCode || 500;
  let redirectParams = `?error_status=${status}&error_data=${error?.response}`
  if (redirectRoute) {
    return {
      redirect: {
        destination: redirectRoute + redirectParams,
        permanent: false,
      },
    };
  }
  let redirect: string = '';
  switch (status) {
    case 401:
      redirect = '/401' + redirectParams;
      break;
    case 404:
      redirect = '/404' + redirectParams;
      break;
    case 500:
    default:
      redirect = '/500' + redirectParams;
      break;
  }
  return {
    redirect: {
      destination: redirect + redirectParams,
      permanent: false,
    }
  }
}
