'use client';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Script from 'next/script';
import { ApplicationState } from '../../types/state/storeTypes';
import NoSSR from 'react-no-ssr';
import { ENV } from '../../constants/environments';

const ChurnkeyFailedPaymentWall: React.FC = (props: any) => {
  useEffect(() => {
    if ((window as any).churnkey === undefined || (window as any)?.churnkey?.check == undefined) {
      return;
    }

    (window as any).churnkey.check('failed-payment', {
      customerId: props?.user?.stripe_customer_token,
      authHash: props?.subscription?.churnkey_hmac,
      appId: 'qheizsbpu',
      mode: ENV.environment == 'production' ? 'live' : 'test',
      provider: 'stripe',
      softWall: true,
      forceCheck: false,
      gracePeriodDays: 0,
      ignoreInvoicesWithoutAttempt: false,
    })
  }, [(window as any)?.churnkey]);

  return (
    <>
      <Script
        id="churnkey-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(){  
              if (!window.churnkey || !window.churnkey.created) {
                window.churnkey = { created: true };
                const a = document.createElement('script');
                a.src = 'https://assets.churnkey.co/js/app.js?appId=qheizsbpu';
                a.async = true;
                const b = document.getElementsByTagName('script')[0];
                b.parentNode.insertBefore(a, b);
              }
            }();
          `,
        }}
      />
    </>
  );
};


const mapStateToProps = (state: ApplicationState): any => ({
  user: state.auth.user,
  subscription: state.auth.subscription,
});

const ConnectedChurnkey = connect(mapStateToProps)(ChurnkeyFailedPaymentWall);

export default (props) => (
  <NoSSR>
    <ConnectedChurnkey {...props} />
  </NoSSR>
);
