import { PlanVariantTier, SubscriptionInterval } from '../types/api/SubscriptionTypes';

export const NOTIFICATION_HEIGHT_PX = 80;
export const NOTIFICATION_WIDTH_PX = 461;
export const NOTIFICATION_MARGIN_TOP_PX = 16;
export const NOTIFICATION_DURATION_SEC = 10;

export const RESET_COPY_TO_CLIPBOARD_BUTTON_UI_INTERVAL = 10;

export const ANIMATE_FRAME_REFRESH_INTERVAL = 80;

export const EMAIL_NOT_VERIFIED_MESSAGE =
  'Your email address is not verified yet. This means you are not able to download any content. Please check your email inbox and verify the email address!';

export const MAX_WISHLIST_ITEMS_COUNT = 50;

export const MAX_PFL_DOWNLOADS_COUNT = 10;

export const NOTIFICATION_STYLE = {
  marginTop: `${NOTIFICATION_MARGIN_TOP_PX.toString()}px`,
  height: `${NOTIFICATION_HEIGHT_PX}px`,
  width: `${NOTIFICATION_WIDTH_PX}px`,
  marginRight: '32px',
  overflow: 'hidden',
  display: 'block'
};

export const NOTIFICATION_MOBILE_STYLE = {
  marginTop: `${NOTIFICATION_MARGIN_TOP_PX.toString()}px`,
  height: `${NOTIFICATION_HEIGHT_PX}px`,
  width: `calc(100vw - 16px)`,
  marginRight: '8px',
  marginLeft: '8px'
};

export const homepageBackgroundImageSource = 'https://a-us.storyblok.com/f/1002378/1920x700/9dcc42a9b1/front_page_static_banner.jpg';
export const homepageBackgroundVideoSource = 'https://a-us.storyblok.com/f/1002378/x/1f6178b540/actionvfxfrontpage15.mp4';

export const PAY_BY_CARD_BUTTON_TEXT = 'Confirm & Pay';
export const CONFIRM_FREE_PURCHASE_BUTTON_TEXT = 'Confirm';
export const PAY_BY_PAYPAL_BUTTON_TEXT = 'Continue to PayPal';

export const SECONDS_BEFORE_REDIRECT = 5 * 1000;

export const REGULAR_PRICING_PAGE_URL = '/pricing';
export const ENTERPRISE_PRICING_PAGE_URL = '/pricing/enterprise';

export const HOW_DO_PLANS_WORK_VIDEO_URL =
  'https://www.youtube.com/embed/f9ntpwS6zUo?si=5my-C8cnbwoWAz1P&autoplay=1&mute=1';

export const DEFAULT_PLAN_VARIANT_TIER_SELECTION: PlanVariantTier = 'basic';
export const DEFAULT_PLAN_VARIANT_INTERVAL_SELECTION: SubscriptionInterval = 'year';
