import { PlanVariantSelectionProps, PlanVariantSelectionStateProps } from '../../types/components/subscription-upgrade-modal/PlanVariantSelection';
import { SubscriptionDetail, PlanVariantResponse, PlanVariantTier } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { DEFAULT_PLAN_VARIANT_TIER_SELECTION } from '../../constants/ui';

import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

import PlanPriceTag from '../shared/PlanPriceTag';
import { CoinsIcon } from '../../modules/Icons';

export const activePlanVariantButtonClass = 'bg-a-blue text-white border-a-blue';
export const inactivePlanVariantButtonClass = 'bg-transparent text-gray-600 border-gray-600';
export const disabledPlanVariantButtonClass = 'bg-gray-800 text-gray-600 border-gray-800 cursor-default';

export const activePlanVariantTierButtonClass = 'text-white bg-a-blue border-a-blue';
export const inactivePlanVariantTierButtonClass = 'text-a-gray border-[#7F898D] bg-opacity-0 hover:border-a-blue hover:bg-a-blue hover:bg-opacity-30'
export const planVariantTierButtonBasicClass = 'rounded-[4px] border-[1px] border-solid px-2 py-1.5 flex gap-2 items-center';
export const disabledPlanVariantTierButtonClass = 'bg-gray-800 text-gray-600 border-gray-800 cursor-default';

const PlanVariantSelection: React.FC<PlanVariantSelectionProps> = ({
  filteredPlanVariants,
  selectedInterval,
  currentPlan,
  selectedPlanVariant,
  onSelectedPlanVariant,
}) => {
  // TODO test
  const [plusPlanVariants, setPlusPlanVariants] = useState<Array<PlanVariantResponse>>([]);
  const [basicPlanVariant, setBasicPlanVariant] = useState<PlanVariantResponse>();
  const [selectedPlanVariantTier, setSelectedPlanVariantTier] = useState<PlanVariantTier>();

  useEffect(() => {
    if (!filteredPlanVariants) return;
    const plusPlanVariants = filteredPlanVariants.filter(pv => pv.recurring_credits > 0);
    setPlusPlanVariants(plusPlanVariants);
    const basicPlanVariant = filteredPlanVariants.find(pv => pv.recurring_credits === 0);
    setBasicPlanVariant(basicPlanVariant);
  }, [filteredPlanVariants]);

  useEffect(() => {
    if (!!selectedPlanVariantTier) return;
    if (!filteredPlanVariants || !selectedPlanVariant) return setSelectedPlanVariantTier(DEFAULT_PLAN_VARIANT_TIER_SELECTION);
    const _selectedPlanVariantTier = selectedPlanVariant.recurring_credits === 0 ? 'basic' : 'plus';
    setSelectedPlanVariantTier(_selectedPlanVariantTier)
  }, [filteredPlanVariants, selectedPlanVariant]);

  useEffect(() => {
    if (!plusPlanVariants || !basicPlanVariant || !selectedPlanVariantTier) return;
    switch (selectedPlanVariantTier) {
      case 'basic':
        if (isCurrentPlan(basicPlanVariant)) {
          setSelectedPlanVariantTier('plus');
          selectVariant(plusPlanVariants[0])
        } else selectVariant(basicPlanVariant);
        break;
      case 'plus':
        const firstAvailablePlan = plusPlanVariants.find(pv => !isCurrentPlan(pv));
        selectVariant(firstAvailablePlan);
        break;
      default:
        break;
    }
  }, [selectedInterval, plusPlanVariants, basicPlanVariant, selectedPlanVariantTier]);

  const isCurrentPlan = (plan: PlanVariantResponse): boolean => {
    if (!currentPlan || !plan) return false;
    return plan.group_id === currentPlan.plan_variant.group_id;
  }

  const getTitle = (): string => selectedPlanVariant?.plan.name || '';

  const getSubtitle = (): string => {
    switch (getTitle()) {
      case 'Individual': return 'For individual artists earning under $200K/year in revenue';
      case 'Startup': return 'For small studios and teams earning under $3M/year in revenue';
      default: return '';
    }
  }

  const renderTitleBlock = (): React.ReactElement => {
    return (
      <div>
        <h3 cy-test-id="title" className="font-bold text-white inter text-28 leading-40">
          {getTitle()}
        </h3>
        <h5 cy-test-id="subtitle" className="mt-2 font-normal text-a-gray inter text-16 leading-24">
          {getSubtitle()}
        </h5>
      </div>
    );
  }

  const selectVariant = (variant: PlanVariantResponse) => {
    if (isCurrentPlan(variant)) return;
    onSelectedPlanVariant(variant);
  }

  const onPlanVariantTierSelected = (tier: PlanVariantTier) => {
    setSelectedPlanVariantTier(tier);
    switch (tier) {
      case 'basic':
        onSelectedPlanVariant(basicPlanVariant);
        break;
      case 'plus':
        const firstAvailablePlan = plusPlanVariants.find(pv => !isCurrentPlan(pv));
        selectVariant(firstAvailablePlan);
        break;
      default:
        return;
    }
  }

  const renderPlanVariantTierSelection = (): React.ReactElement => {
    return (
      <div className="flex gap-2" cy-test-id="tier-selection">
        <button
          onClick={() => onPlanVariantTierSelected('basic')}
          className={`
            ${!isCurrentPlan(basicPlanVariant) && selectedPlanVariantTier === 'basic' ? activePlanVariantTierButtonClass : inactivePlanVariantTierButtonClass}
            ${isCurrentPlan(basicPlanVariant) && disabledPlanVariantTierButtonClass}
            ${planVariantTierButtonBasicClass}
          `}
          cy-test-id="basic-button"
          disabled={isCurrentPlan(basicPlanVariant)}
        >
          Essentials
        </button>
        <button
          onClick={() => onPlanVariantTierSelected('plus')}
          className={`
            ${selectedPlanVariantTier === 'plus' ? activePlanVariantTierButtonClass : inactivePlanVariantTierButtonClass}
            ${planVariantTierButtonBasicClass}
          `}
          cy-test-id="plus-button"
        >
          <CoinsIcon className="stroke-white w-[20px] h-[20px]" />
          Plus
        </button>
      </div>
    )
  }

  const renderVariantSelectionButton = (variant: PlanVariantResponse): React.ReactElement => {
    return (
      <button
        key={variant.id}
        cy-test-id={`variant-selection-button-${variant.group_id}`}
        className={`
          py-1.5 px-5 rounded-[33px] h-10 inter text-16 leading-24 font-medium border-[1px]
          ${isCurrentPlan(variant) && disabledPlanVariantButtonClass}
          ${variant.id === selectedPlanVariant?.id && activePlanVariantButtonClass}
          ${!isCurrentPlan(variant) && variant.id !== selectedPlanVariant?.id && inactivePlanVariantButtonClass}
        `}
        disabled={isCurrentPlan(variant)}
        onClick={() => selectVariant(variant)}
      >
        {variant.recurring_credits}
      </button>
    );
  }

  const renderPlusPlanVariantSelectionBlock = (): React.ReactElement => {
    if (selectedPlanVariantTier === 'basic') return;
    return (
      <div>
        <h5 cy-test-id="credits-selection-label" className="font-semibold text-white inter text-16 leading-24">
          Credits per {selectedInterval}
        </h5>
        <div className="flex flex-row w-full gap-2 mt-2">
          {plusPlanVariants.map(variant => renderVariantSelectionButton(variant))}
        </div>
      </div>
    );
  }

  return (
    <div cy-test-id="plan-variant-selection" className="w-full flex flex-col gap-6 p-6 bg-a-dark-gray rounded-[5px]">
      {renderTitleBlock()}
      {renderPlanVariantTierSelection()}
      {renderPlusPlanVariantSelectionBlock()}
      <div className="w-full mt-6">
        {selectedPlanVariant && (
          <div cy-test-id="price-holder">
            <PlanPriceTag plan={selectedPlanVariant} alignment="vertical"/>
          </div>
        )}
      </div>
    </div>
  )
};

const mapStateToProps = (state: ApplicationState): PlanVariantSelectionStateProps => ({
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(PlanVariantSelection);
