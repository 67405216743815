import { PlanPriceTagProps } from '../../types/components/shared/PlanPriceTag';
import { SubscriptionInterval } from '../../types/api/SubscriptionTypes';

export const wrapperHorizontalAlignmentClass = 'flex-row gap-3';
export const wrapperVerticalAlignmentClass = 'flex-col gap-1';

// TODO update tests
const PlanPriceTag: React.FC<PlanPriceTagProps> = ({
  plan,
  freeForever,
  shortenIntervalStrings,
  alignment = 'horizontal',
  displayDecimals = false,
}) => {
  const formatPrice = (price: number): string => {
    return price.toLocaleString('en-US', {
      minimumFractionDigits: displayDecimals ? 2 : 0,
      maximumFractionDigits: displayDecimals ? 2 : 0
    });
  }

  const getPlanMonthlyPrice = (): string => {
    const price = plan?.adjusted_price || plan?.price || '0';
    let priceFloat = parseFloat(price);
    if (plan.interval === 'year') priceFloat = priceFloat / 12;
    return formatPrice(priceFloat);
  }

  const getPlanYearlyPrice = (): string => {
    const price = plan?.adjusted_price || plan?.price || '0';
    let priceFloat = parseFloat(price);
    return formatPrice(priceFloat);

  }

  const shortenIntervalString = (string: SubscriptionInterval): string => {
    switch (string) {
      case 'month': return 'mo';
      case 'year': return 'yr';
      default: return '';
    }
  }

  const getIntervalStringFor = (interval: SubscriptionInterval): string => {
    return shortenIntervalStrings ? shortenIntervalString(interval) : interval;
  }

  const renderYearlyPriceForAnnualPlan = (): React.ReactElement => {
    if (freeForever || plan?.interval !== 'year') return;
    return (
      <div className="flex flex-row items-center" cy-test-id="yearly-price-for-annual">
        <p className="text-[14px] text-a-gray" cy-test-id="price-block">
          <span>billed annually at </span>
          <span className="text-[12px]">$</span>
          <span>{getPlanYearlyPrice()}</span>
        </p>
        <p
          className="text-a-gray text-[14px]" cy-test-id="interval">
          / {getIntervalStringFor('year')}
        </p>
      </div>
    )
  }

  return (
    <div className={`
      flex items-center mb-6 w-fit
      ${alignment === 'horizontal' ? wrapperHorizontalAlignmentClass : wrapperVerticalAlignmentClass}
      `} cy-test-id="plan-price-tag">
      {
        plan &&
        <>
          {
            plan?.adjusted_price &&
            <span cy-test-id="old-price-block" className="text-[18px] leading-[18px] text-red-800 line-through">{`$${plan?.price?.split('.')[0]}`}</span>
          }
          <div className="flex flex-row items-center gap-2">
            <p className={`${plan.adjusted_price ? 'text-[28px] md:text-[36px]' : 'max-xl:text-[28px] xl:text-[50px]'} font-bold md:leading-[44px] text-white`} cy-test-id="price-block">
              <span className="text-[24px] md:align-bottom inline-block">$</span>
              <span className="align-text-top">{getPlanMonthlyPrice()}</span>
            </p>
            <p
              className="text-a-gray text-[16px]" cy-test-id="interval">
              / {getIntervalStringFor('month')}
            </p>
          </div>
          {renderYearlyPriceForAnnualPlan()}
        </>
      }
      {
        freeForever &&
        <p className="text-[50px] font-bold md:leading-[44px]" cy-test-id="free-forever-block">
          <span className="text-[24px] md:align-bottom inline-block">$</span>
          <span className="align-text-top">0</span>
        </p>
      }
    </div>
  );
}

export default PlanPriceTag;
