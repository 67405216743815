/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './app/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layout/**/*.{js,ts,jsx,tsx}',
    './modules/**/*.{js,ts,jsx,tsx}',

    // Or if using `src` directory:
    './src/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      keyframes: {
        'slide-right': {
          '0%': { transform: 'translateX(20px)', opacity: '0' },
          '20%': { transform: 'translateX(20px)', opacity: '1' },
          '80%': { transform: 'translateX(-20px)', opacity: '1' },
          '100%': { transform: 'translateX(-20px)', opacity: '0' }
        },
        'slide-left': {
          '0%': { transform: 'translateX(-20px)', opacity: '0' },
          '20%': { transform: 'translateX(-20px)', opacity: '1' },
          '80%': { transform: 'translateX(20px)', opacity: '1' },
          '100%': { transform: 'translateX(20px)', opacity: '0' }
        },
        'container-slide-right': {
          '0%': { transform: 'translateX(0)' },
          '20%': { transform: 'translateX(0)' },
          '80%': { transform: 'translateX(-30px)' },
          '100%': { transform: 'translateX(0)' }
        },
        'container-slide-left': {
          '0%': { transform: 'translateX(0)' },
          '20%': { transform: 'translateX(0)' },
          '80%': { transform: 'translateX(30px)' },
          '100%': { transform: 'translateX(0)' }
        }
      },
      animation: {
        'pulse-fast': 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        'slide-right': 'slide-right 2.5s ease-in-out infinite',
        'slide-left': 'slide-left 2.5s ease-in-out infinite',
        'container-slide-right': 'container-slide-right 2.5s ease-in-out infinite',
        'container-slide-left': 'container-slide-left 2.5s ease-in-out infinite'
      },
      screens: {
        'ultra-wide': '3440px',
        '4k': '2000px',
        '1900p': '1900px',
        '1920p': '1920px',
        'mobile-s': '320px',
        'mobile-m': '375px',
        'mobile-l': '425px',
        '1024p': '1024px'
      },

      fontFamily: {
        sans: ['Inter']
      },
      lineHeight: {
        44: '44px',
        40: '40px',
        36: '36px',
        32: '32px',
        28: '28px',
        24: '24px',
        22: '22px',
        20: '20px',
        16: '16px',
      },
      letterSpacing: {
        wide: '.02em'
      },
      fontSize: {
        36: '36px',
        24: '24px',
        40: '40px',
        34: '34px',
        32: '32px',
        28: '28px',
        21: '21px',
        20: '20px',
        18: '18px',
        17: '17px',
        16: '16px',
        14: '14px',
        12: '12px',
      },
      colors: {
        'a-darker-gray': '#0B1113',
        'a-dark-gray': '#15191B',
        'a-blue': '#00A1E0',
        'a-light-blue': '#24C1FF',
        'a-gray': '#AEB6BA',
        'a-light-gray': '#7F898D',
        'a-lighter-gray': '#AFAFB0',
        'a-green': '#00B74F',
        'a-orange': '#CB7A00',
        'a-light-orange': '#FF9D00',
        'a-yellow': '#E3CC00',
        'gray-800': '#252B2D',
        'gray-700': '#363839',
        'gray-600': '#7F898D',
        'gray-500': '#AEB6BA',
        'gray-900': '#15191B',
        'gray-1000': '#0B1113',
        white: '#F5F5F5',
        'green-1000': '#018138',
        'green-80': '#00DA5E',
        'blue-800': '#24C1FF',
        'blue-1000': '#0075A3',
        'red-100': '#812126',
        'red-800': '#CB333B',
        'red-900': '#A22A30',
        'orange-900': '#BE7500',
        'a-blue-7': 'rgba(17, 76, 99, 0.7)',
        'white-5': 'rgba(255, 255, 255, 0.05)',
        'white-10': 'rgba(255, 255, 255, 0.1)',
        'white-50': 'rgba(255, 255, 255, 0.5)',
        'white-60': 'rgba(255, 255, 255, 0.6)',
        'white-80': 'rgba(255, 255, 255, 0.8)',
        'white-95': 'rgba(255, 255, 255, 0.95)',
        'a-darkgreen-10': 'rgba(0, 183, 79, 0.1)',
        'black-friday-banner-red-default': '#A12A30',
        'black-friday-banner-red-brighter': '#F04049',
        'gradient-light': '#112D38',
        'gradient-dark': '#0B1114',
        'orange-800': '#FFAC26',
        'orange-800-20': 'rgba(255, 157, 0, 0.2)',
        'grey-70': '#4D5457',
      },
      backgroundImage: (theme) => ({
        'custom-gradient': `linear-gradient(to right, ${theme(
          'colors.gradient-light'
        )} 20%, ${theme('colors.gradient-dark')} 30%, ${theme('colors.gradient-dark')} 90%)`
      })
    },
  },
  plugins: []
};
